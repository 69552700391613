#root {
	height: 100vh;
	width: 100vw;
}

html,
body {
	width: 100%;
	height: 100%;
}

.container-fluid {
	padding: 0;
}

div.App {
	opacity: 0.4;
	transition: opacity 0.5s;
}

div.App:hover {
	opacity: 1;
}

.App {
	margin: 0;
	padding: 0;
	display: flex;
	height: inherit;
}

.nav-row {
	width: inherit;
	z-index: 3;
	position: absolute;
	flex-basis: row wrap;
	justify-content: space-between;
}

.sound-toggle {
	position: absolute;
	top: 0;
	right: 0;
	background-color: rgba(255, 255, 255, 0.7);
}

iframe {
	pointer-events: none;
	cursor: default;
}

Wallpaper.videoWrapper iframe,
.videoWrapper > #widget2 {
	z-index: -5 !important;
	position: absolute;
	padding: 0;
	top: 0;
	left: 0;
	margin: 0;
}

.spinner {
	z-index: 2;
}
